<template>
  <section
    id="leaderboard"
    class="relative w-full overflow-hidden p-2 py-24 pb-36 text-center sm:px-4 md:px-8 lg:px-16 xl:px-24"
  >
    <img
      v-motion-pop-visible
      class="z-50 mx-auto h-auto w-80"
      src="@/assets/leaderboard-logo.png"
      alt="leaderboard logo"
    />
    <img
      class="absolute -top-20 z-10 w-full overflow-hidden bg-cover"
      src="@/assets/leaderboard-stars.png"
      alt="small stars"
    />
    <!-- leaderboards timer -->
    <div v-if="roobetTimer && roobetTimer != 'Leaderboard ended'" class="flex flex-row justify-center gap-2 py-5">
      <TimeCounter :roobetTimer="roobetTimer" />
    </div>
    <div v-else class="py-2 text-2xl font-bold text-white md:text-[36px] md:leading-[48px]">
      {{ loading == true ? 'Calculating time' : 'Leaderboard Ended' }}
    </div>

    <!-- medium cloud right overlay -->
    <img
      class="absolute top-12 -right-24 z-10 h-[4em] overflow-hidden sm:h-[6em] md:h-[9.5em]"
      src="@/assets/medium-cloud-1.png"
      alt="medium clouds"
    />
    <img
      class="absolute top-80 -left-20 z-10 h-[4em] overflow-hidden sm:top-96 sm:h-[6em] md:h-[9.5em]"
      src="@/assets/medium-cloud-2.png"
      alt="medium  clouds"
    />

    <!-- leaderboards top 3 -->
    <div v-if="loading == true" class="mt-10 h-screen text-center text-xl font-bold text-white md:text-4xl">
      Loading Leaderboard...
    </div>
    <div v-if="loading == false" class="relative z-50 w-full">
      <div class="flex flex-row items-center justify-center gap-2 sm:gap-10 md:gap-20 lg:mt-32 lg:gap-24">
        <div v-motion-pop-visible class="mt-80 flex flex-col items-center lg:mt-[88px]">
          <LeaderboardCard
            :top3="top3"
            rank="2"
            :imageSrc="require('@/assets/leaderboard-2nd.png')"
            imageAlt="2nd Place Leaderboard"
          />
        </div>
        <div v-motion-pop-visible class="absolute top-12 flex w-[150px] flex-col items-center lg:relative lg:-mt-40">
          <LeaderboardCard
            :top3="top3"
            rank="1"
            :imageSrc="require('@/assets/leaderboard-1st.png')"
            imageAlt="1st Place Leaderboard"
          />
        </div>
        <div v-motion-pop-visible class="mt-80 flex flex-col items-center lg:mt-[88px]">
          <LeaderboardCard
            :top3="top3"
            rank="3"
            :imageSrc="require('@/assets/leaderboard-3rd.png')"
            imageAlt="3rd Place Leaderboard"
          />
        </div>
      </div>
    </div>

    <!-- small cloud right overlay -->
    <img
      class="absolute top-1/2 right-0 z-10 h-[4em] overflow-hidden sm:right-20 md:h-[5em]"
      src="@/assets/small-cloud-1.png"
      alt="small clouds"
    />
    <img
      class="absolute top-2/3 left-0 z-10 h-[4em] overflow-hidden sm:top-1/2 sm:-left-20 md:h-[5em]"
      src="@/assets/small-cloud-2.png"
      alt="small  clouds"
    />

    <!-- leaderboards table -->
    <!-- <div class="mb:mb-24 mt-8 flex justify-center lg:mt-[91px]"> -->
    <div class="mb:mb-24 mx-auto mt-8 flex justify-center px-0 sm:max-w-4xl lg:mt-[91px] lg:max-w-6xl 2xl:max-w-7xl">
      <!-- <table
          class="z-20 w-full lg:w-auto border-[6px] border-[#FFC125]  border-separate border-spacing-y-6 bg-gradient-to-t from-[#FFFFFF15] to-[#D9D9D90B] backdrop-blur-5xl rounded-[40px]" > -->
      <table
        class="z-20 w-full border-separate border-spacing-y-6 rounded-[50px] border-[6px] border-[#FFC125]/70 shadow-xl"
        style="
          background: linear-gradient(to top, #ffffff15, #d9d9d90b);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border-radius: 40px;
        "
      >
        <thead v-motion-slide-visible-bottom class="bg-transparent">
          <tr>
            <th
              class="rounded-l py-2 pl-4 pr-2 text-right font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:pr-12 xl:pl-24"
            >
              RANK
            </th>
            <th
              class="px-1 py-2 text-center font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:px-2"
            >
              USER
            </th>
            <th
              class="px-1 py-2 text-center font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:px-2"
            >
              WAGERED
            </th>
            <th
              class="rounded-r py-2 pr-4 pl-1 text-left font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:pl-12 xl:pr-24"
            >
              PRIZE
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-motion-slide-visible-bottom
            v-for="(item, index) in topRest"
            :key="index"
            class="rounded bg-transparent font-inter text-base"
          >
            <td
              class="whitespace-nowrap px-2 py-2 text-right text-base font-normal uppercase md:px-5 md:py-4 lg:px-[45px] xl:px-12"
            >
              <div class="tracking-[4px] text-white">
                {{ getOrdinalSuffix(index + 4) }}
              </div>
            </td>
            <td
              class="max-w-[76px] overflow-hidden text-ellipsis whitespace-nowrap px-2 py-2 text-center text-base font-normal tracking-[4px] text-white md:max-w-full md:px-5 md:py-5 lg:px-[45px] xl:px-28"
            >
              {{ getMaskstring(item.username || '') }}
            </td>
            <td
              class="whitespace-nowrap px-1 py-2 text-center text-base font-normal tracking-[4px] text-white md:px-5 md:py-5 lg:px-[45px] xl:px-28"
            >
              $ {{ item.weightedWagered ? item.weightedWagered.toFixed(2) : '' }}
            </td>
            <td
              class="whitespace-nowrap px-1 py-2 text-left text-base font-black tracking-[4px] text-white md:px-5 md:py-5 lg:px-[45px] xl:px-12"
            >
              <span class="text-inherit text-[#F8BC27]">{{ index + 3 > 7 ? '$' : '$' }}</span>
              {{ getRoobetRewardValue(index + 3) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- </div> -->

    <img class="absolute left-0 bottom-80 w-full" src="@/assets/leaderboard-table-bg.png" alt="translucent clouds" />

    <!-- overlay -->

    <img
      class="absolute bottom-12 -right-32 z-10 h-[7.5em] overflow-hidden md:-right-72 md:h-[18.75em]"
      src="@/assets/leaderboard-cloud-right.png"
      alt="right clouds"
    />

    <img
      class="absolute bottom-12 -left-32 z-10 h-[6.25em] overflow-hidden md:-left-72 md:h-[15.625em]"
      src="@/assets/leaderboard-cloud-left.png"
      alt="left clouds"
    />

    <img class="absolute left-0 -bottom-4 w-full" src="@/assets/leaderboard-white-curve.png" alt="translucent clouds" />

    <PrevLeaderBoardSection ref="PrevLeaderBoardSection" />
    <ChallengesSection />
  </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import PrevLeaderBoardSection from './PrevLeaderBoardSection.vue';
import TimeCounter from '@/components/TimeCounter.vue';
import LeaderboardCard from '@/components/LeaderboardCard.vue';
import ChallengesSection from '@/sections/ChallengesSection.vue';
// import { ROOBET_PRIZES_CURRENT } from '@/const';
export default {
  components: {
    PrevLeaderBoardSection,
    TimeCounter,
    LeaderboardCard,
    ChallengesSection,
  },
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],
      roobetTimer: '',

      roobetStartDate: null,
      roobetEndDate: null,

      roobetNotFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;
      await axios
        .get(`${baseUrl}/api/roobet/leaderboard`, {
          headers: {
            'x-api-key': process.env.VUE_APP_X_API_KEY,
          },
        })
        .then((res) => {
          this.roobetLeaderboard = res.data.leaderboard || [];
          this.roobetNotFormattedEndDate = res.data.endDate;

          const defaultPlaceholder = { username: '', weightedWagered: 0, prize: 0 };
          const leaderboard = this.roobetLeaderboard;

          // Ensure at least 13 entries
          while (leaderboard.length < 15) {
            leaderboard.push({ ...defaultPlaceholder });
          }

          this.top3 = leaderboard.slice(0, 3);
          this.topRest = leaderboard.slice(3, 15);
          this.topForMobile = leaderboard.slice(0, 5);

          this.startRoobetTimer();
          this.loading = false;
        })
        .catch((error) => {
          console.error('Error fetching leaderboard:', error);
          this.loading = false;
        });
    },
    getOrdinalSuffix(n) {
      const s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getMaskstring(string) {
      if (string) {
        const firstHalf = Math.ceil(Number(string.length / 2));
        const secondHalf = string.length - firstHalf;

        return string.slice(0, firstHalf) + '*'.repeat(secondHalf);
      }

      return string;
    },
    getRoobetRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_CURRENT_PRIZES);
      return prizes[rank] || '';
    },
    startRoobetTimer() {
      const getNextTargetDate = () => {
        // const now = moment.tz('America/New_York');
        // const now = moment.utc();
        // let targetDate = moment.tz(this.roobetNotFormattedEndDate, 'America/New_York')
        //   .hour(23)
        //   .minute(59)
        //   .second(59)
        //   .subtract(1, 'days');
        let targetDate = moment.utc(this.roobetNotFormattedEndDate).subtract(1, 'seconds');

        // if (now.isAfter(targetDate)) {
        //   targetDate.add(1, 'month');
        // }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let roobetTimerInterval;

      const updateTimer = () => {
        // const nowEst = moment.tz('America/New_York');
        const nowEst = moment().utc();
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = 'Leaderboard ended';
          this.init();
          this.$refs.PrevLeaderBoardSection.initPrevLeaderboard();
          clearInterval(roobetTimerInterval);

          targetDate = getNextTargetDate();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };
      updateTimer();
      roobetTimerInterval = setInterval(updateTimer, 1000);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
