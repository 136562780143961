<template>
  <section class="prev-leaderboard-bg h-auto">
    <div class="px-2 py-14 lg:py-24">
      <div class="flex flex-row items-center justify-center">
        <!-- <img v-motion-pop-visible src="../assets/prev-lead-title.png" class="lg:px-52" alt="prev-leaderboard" /> -->
        <h1 class="text-5xl text-white">PREVIOUS LEADERBOARD</h1>
      </div>
      <!-- <div
        v-if="prevloading == true"
        class="mt-10 text-2xl font-bold text-center text-white md:text-4xl"
        style="
          font-family:
            Russo One,
            sans-serif;
        "
      >
        Waiting for Reset...
      </div> -->
      <div v-if="prevloading == false">
        <div
          class="mb:mb-24 mx-auto mt-8 flex justify-center px-0 sm:max-w-4xl lg:mt-[91px] lg:max-w-6xl 2xl:max-w-7xl"
          style="
            font-family:
              Russo One,
              sans-serif;
          "
        >
          <table
            class="z-20 w-full border-separate border-spacing-y-6 rounded-[50px] border-[6px] border-[#FFC125]/70 shadow-xl"
            style="
              background: linear-gradient(to top, #ffffff15, #d9d9d90b);
              backdrop-filter: blur(10px);
              -webkit-backdrop-filter: blur(10px);
              border-radius: 40px;
            "
          >
            <thead v-motion-slide-visible-bottom class="bg-transparent">
              <tr>
                <th
                  class="rounded-l py-2 pl-4 pr-2 text-right font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:pr-12 xl:pl-24"
                >
                  RANK
                </th>
                <th
                  class="px-1 py-2 text-center font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:px-2"
                >
                  USER
                </th>
                <th
                  class="px-1 py-2 text-center font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:px-2"
                >
                  WAGERED
                </th>
                <th
                  class="rounded-r py-2 pr-4 pl-1 text-left font-inter text-lg font-bold leading-6 text-white md:px-5 md:py-4 lg:px-[45px] xl:pl-12 xl:pr-24"
                >
                  PRIZE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-motion-slide-visible-bottom
                v-for="(item, index) in displayedPrevLeadMobile"
                :key="index"
                class="rounded bg-transparent font-inter text-base"
              >
                <td
                  class="whitespace-nowrap px-2 py-2 text-right text-base font-normal uppercase md:px-5 md:py-4 lg:px-[45px] xl:px-12"
                >
                  <div class="tracking-[4px] text-white">
                    {{ getOrdinalSuffix(index + 1) }}
                  </div>
                </td>
                <td
                  class="max-w-[76px] overflow-hidden text-ellipsis whitespace-nowrap px-2 py-2 text-center text-base font-normal tracking-[4px] text-white md:max-w-full md:px-5 md:py-5 lg:px-[45px] xl:px-28"
                >
                  {{ getMaskstring(item.username || '') }}
                </td>
                <td
                  class="whitespace-nowrap px-1 py-2 text-center text-base font-normal tracking-[4px] text-white md:px-5 md:py-5 lg:px-[45px] xl:px-28"
                >
                  $ {{ item.weightedWagered ? item.weightedWagered.toFixed(2) : '' }}
                </td>
                <td
                  class="whitespace-nowrap px-1 py-2 text-left text-base font-black tracking-[4px] text-white md:px-5 md:py-5 lg:px-[45px] xl:px-12"
                >
                  <span class="text-inherit text-[#F8BC27]">{{ index + 3 > 7 ? '$' : '$' }}</span>
                  {{ getRoobetRewardValue(index) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
// import { ROOBET_PRIZES_CURRENT } from '@/const';

export default {
  data() {
    return {
      displayedPrevLead: [],
      roobetPrevLeaderboard: [],
      packdrawPrevLeaderboard: [],
      displayedPrevLeadMobile: [],
      prevloading: false,

      selectedPrevLeaderboard: true,
    };
  },
  methods: {
    initPrevLeaderboard() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.prevloading = true;
      axios
        .get(`${baseUrl}/api/roobet/leaderboard?previous=true`, {
          headers: {
            'x-api-key': process.env.VUE_APP_X_API_KEY,
          },
        })
        .then((res) => {
          this.roobetPrevLeaderboard = res.data.leaderboard;

          this.displayedPrevLead = this.roobetPrevLeaderboard.slice(0, 15).map((item) => {
            return item;
          });
          this.displayedPrevLeadMobile = this.roobetPrevLeaderboard.slice(0, 15).map((item) => {
            return item;
          });

          this.prevloading = false;
        });
    },

    getOrdinalSuffix(n) {
      const s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getMaskstring(string) {
      if (string) {
        const firstHalf = Math.ceil(Number(string.length / 2));
        const secondHalf = string.length - firstHalf;

        return string.slice(0, firstHalf) + '*'.repeat(secondHalf);
      }

      return string;
    },
    getRoobetRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_PREV_PRIZES);
      return prizes[rank];
    },
    getPackDrawRewardValue(rank) {
      const prizes = [200, 100, 50, 50];
      if (rank >= 4) {
        return 25;
      }
      return prizes[rank];
    },
  },
  mounted() {
    this.initPrevLeaderboard();
  },
};
</script>

<style scoped>
.prev-leaderboard-bg {
  /* background-image: url('../assets/previous-leaderboard-bg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
